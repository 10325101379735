import {Component} from '@angular/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {IconService} from './services/icon.service';
import {IntentActionService} from './services/intent-action.service';
import {PortalMessageBroker} from './portal/portal-message-broker';
import {ContextInfoService, WellKnownJustFarmingProperties} from './portal/context-info.service';
import {MatomoTracker} from '@ngx-matomo/tracker';
import {Router} from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private readonly unsubscribe$ = new Subject<void>();

  runningInPortal$: Observable<boolean>;

  constructor(
    iconService: IconService, // INFO: use for instantiation
    intentActionService: IntentActionService, // INFO: use for instantiation
    private portalMessageBroker: PortalMessageBroker,
    private oidcSecurityService: OidcSecurityService,
    private contextInfoService: ContextInfoService,
    private readonly tracker: MatomoTracker,
    protected router: Router,
  ) {
    this.runningInPortal$ = this.portalMessageBroker.runningInPortal$
      .pipe(
        takeUntil(this.unsubscribe$),
      );

    this.contextInfoService.contextInfo$
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(contextInfo => {
        let analytics = contextInfo?.properties.get(WellKnownJustFarmingProperties.ANALYTICS);
        if (analytics === true) {
          this.tracker.setConsentGiven();
        } else {
          this.tracker.forgetConsentGiven();
        }
      });
  }

  doLogout(): void {
    this.oidcSecurityService.logoff();
  }

  get userInfo$(): Observable<any> {
    return this.oidcSecurityService.userData$
      .pipe(
        takeUntil(this.unsubscribe$),
        map(value => value.userData),
      );
  }
}
