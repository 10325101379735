import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {OidcSecurityService} from 'angular-auth-oidc-client';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  errorCode: string = '';

  constructor(
    private route: ActivatedRoute,
    private oidcSecurityService: OidcSecurityService,
  ) { }

  ngOnInit(): void {
    this.errorCode = this.route.snapshot.paramMap.get('code')?.slice(0, 10) ?? 'unbekannt';
  }

  doLogout(): void {
    this.oidcSecurityService.logoff();
  }
}
