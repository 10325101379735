/**
 * Export
 * API für Export
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 * Contact: support@landdata.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum DirectionDTO {
    Asc = 'ASC',
    Desc = 'DESC'
};

