<div class="container my-5 d-flex justify-content-center ">
  <div class="errorBox bg-body-tertiary p-5 rounded-3">
    <h1>Fehler: {{ errorCode }}</h1>
    <ng-container [ngSwitch]="errorCode">
      <p *ngSwitchCase="403">Deiner Anmeldung fehlen die notwendigen Zugriffsberechtigungen.</p>
      <p *ngSwitchCase="500">
        Es ist ein interner Fehler aufgetreten. Bitte versuche es später erneut und wende dich bei Bedarf an den
        Benutzerservice, falls das Problem fortbesteht.
      </p>
      <p *ngSwitchDefault></p>
    </ng-container>
    <button
      mat-stroked-button
      (click)="doLogout()"
      class="mt-5"
    >Abmelden
    </button>
  </div>
</div>
