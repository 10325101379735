import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';


export type IconNamespace =
  'common'
  | 'card'
  | 'pdf-viewer'
  | 'status'
  | 'actions'
  | 'leftpanel'
  | 'widgets'
  | 'dragdrop';

export interface IconSpec {
  namespace: IconNamespace;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class IconService {

  iconList: IconSpec[] = [
    /*
    {
      namespace: '',
      name: '',
    },
    */
  ];

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    this.iconList.forEach(icon => {
      iconRegistry.addSvgIconInNamespace(
        icon.namespace,
        icon.name,
        sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon.namespace}/${icon.name}.svg`));
    });
  }
}
