import {Injectable} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {Subject} from 'rxjs';


@Injectable()
export class MatPaginatorLocalize implements MatPaginatorIntl {
  changes = new Subject<void>();

  firstPageLabel = $localize`Erste Seite`;
  itemsPerPageLabel = $localize`Elemente pro Seite:`;
  lastPageLabel = $localize`Letzte Seite`;
  nextPageLabel = $localize`Nächste Seite`;
  previousPageLabel = $localize`Vorherige Seite`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`Seite 1 von 1`;
    }

    const amountPages = Math.ceil(length / pageSize);
    return $localize`Seite ${page + 1} von ${amountPages}`;
  }
}
