<mat-toolbar
  [hidden]="router.url.includes('/admin') || (runningInPortal$ | async)"
>
  <span class="standalone-toolbar-spacer"></span>
  <p
    class="username me-2"
    [innerText]="(userInfo$ | async)?.email ?? ''"
  ></p>
  <button
    mat-icon-button
    tabindex="-1"
    matTooltip="Abmelden"
    (click)="doLogout()"
  >
    <mat-icon>logout</mat-icon>
  </button>
</mat-toolbar>

<div class="content-outlet">
  <router-outlet></router-outlet>
</div>
