import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

import {AuthGuard} from './auth/auth.guard';
import {ErrorComponent} from './modules/common/error/error.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/datev/income-tax-documents/config/create',
    pathMatch: 'full'
  },
  {
    path: 'datev',
    loadChildren: () => import('./modules/datev/datev.module').then(m => m.DatevModule),
  },
  {
    path: 'error/:code',
    component: ErrorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      scrollPositionRestoration: 'top',
      preloadingStrategy: PreloadAllModules,
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
